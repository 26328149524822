
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';
import { TUIEnv } from '../TUIKit/TUIPlugin';
import { sayHi } from '../main'
export default defineComponent({
    name: 'App',
    setup() {
        const data = reactive({
            env: TUIEnv(),
            currentModel: 'conversation',
        });
        const handleCurrentConversation = (value: string) => {
            data.currentModel = value ? 'message' : 'conversation';
        };
        watchEffect(() => {
            sayHi(window.localStorage.getItem("userID"))
        });
        return {
            ...toRefs(data),
            handleCurrentConversation,
        };
    },
});
